import axios from 'axios';
import router from "@/router";
const MEME = axios.create({
  // baseURL: 'http://localhost:9527/api/',
  baseURL: 'https://happyplay.game/api/memeApi/',
});

const GAME = axios.create({
  // baseURL: 'http://localhost:9528/api/',
  baseURL: 'https://happyplay.game/api/gameApi/',
});

const request = config => {
    config.headers = {
      'Content-Type': 'multipart/form-data',
      'token': localStorage.getItem("token"),
      'userId': localStorage.getItem("username")
    }

    let lang = {
      zh: 'zh-cn',
      ko: 'ko-kr',
      bn: 'ko-kr',
      en: 'en-us',
    }

    let str = '';

    if(lang[localStorage.getItem('lang')]){
      str = lang[localStorage.getItem('lang')]
    } else str = 'ko-kr'

    config.url += '?lang=' + str;

    return config;
}

const response = response => {
  if (response.data.code == 200) {
    return response;
  } else {
    if(response.data.code == 401){
      localStorage.clear();
      if(router.currentRoute.name != 'index'){
        router.push({
          name: 'index'
        })
      }
    }
    return response;
  }
}

MEME.interceptors.request.use(request)
MEME.interceptors.response.use(response)
GAME.interceptors.request.use(request)
GAME.interceptors.response.use(response)

const memeGet = (url, params) => {
  return new Promise((resolve, reject) => {
    MEME.get(url, {
      params: params
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err.data);
    })
  })
}

const memePost = (url, data) => {
  return new Promise((resolve, reject) => {
    MEME.post(url, data).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err.data);
    })
  })
}

const gameGet = (url, params) => {
  return new Promise((resolve, reject) => {
    GAME.get(url, {
      params: params
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err.data);
    })
  })
}

const gamePost = (url, data) => {
  return new Promise((resolve, reject) => {
    GAME.post(url, data).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err.data);
    })
  })
}

export default {
  memeGet,
  memePost,
  gameGet,
  gamePost,
}
