<template>
  <div>
    <div :class="isAndroid == true ? 'box':'boxios'"></div>
<!--    <div class="flex-row tabbar" :class="isAndroid == true ? 'box_10':'box_10ios'">-->
<!--      -->
<!--    </div>-->

    <div class="flex-row tabbar">
      <div class="newtabbar">
        <div class="image-text flex-col justify-between" @click="goPath('index')">
          <img
              class="label_5" width="38" height="38"
              :src="require(`../../assets/tabbar/${value == 'index' ? 'x0.png' : '0.png'}`)"
          />
          <div :class="value == 'index' ? 'text-group' : 'text'">{{ $t('yx2') }}</div>
        </div>

        <div class="image-text flex-col justify-between" @click="goPath('financing')">
          <img
              class="label_5" width="38" height="38"
              :src="require(`../../assets/tabbar/${value == 'financing' ? 'x1.png' : '1.png'}`)"
          />
          <div :class="value == 'financing' ? 'text-group' : 'text'">{{ $t('lc') }}</div>
        </div>

        <div class="image-text flex-col justify-between" @click="goPath('record')">
          <img
              class="label_5" width="38" height="38"
              :src="require(`../../assets/tabbar/${value == 'record' ? 'x2.png' : '2.png'}`)"
          />
          <div :class="value == 'record' ? 'text-group' : 'text'">{{ $t('jl2') }}</div>
        </div>

        <div class="image-text flex-col justify-between" @click="goPath('my')">
          <img class="label_5" width="38" height="38"
               :src="require(`../../assets/tabbar/${value == 'my' ? 'x3.png' : '3.png'}`)"
          />
          <div :class="value == 'my' ? 'text-group' : 'text'">{{ $t('wd') }}</div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  props: ['value'],
  data() {
    return {
      isAndroid: false
    }
  },
  mounted() {
    let u = navigator.userAgent;
    this.isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
  },
  methods: {
    goPath(val) {
      if (this.value === val) return;
      if (localStorage.getItem('token')) {
        this.$emit('changeTab', val);
        this.$router.push({
          name: val
        })
      } else {
        this.$toast(this.$t('weidenglu'));
      }
    }
  }
}
</script>
<style scoped>

.newtabbar{
  width: 100%;
  height: 90px;
  background: linear-gradient(180deg, #202833, #151B2A);
  border-radius: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}


.box {
  height: 90px;
}

.boxios {
  height: 90px;
}


.tabbar {
  position: fixed;
  bottom: 0;
  padding: 15px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
}

.text {
  text-align: center;
  color: rgba(83, 83, 83, 1);
  font-size: 12px;
  margin-top: 6px;
}

.text-group {
  text-align: center;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  margin-top: 6px;
}

.image-text {
  width: 25%;
}

.label_5 {
  margin: 0 auto;
  display: flex;
}

</style>
