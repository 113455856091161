import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
  {
    path: "/",
    redirect: "loading"
  },
  {
    path: "/loading",
    name: "loading",
    component: () => import('../views/loading')
  },
  {
    path: "/notFound",
    name: "notFound",
    component: () => import('../views/notFound')
  },
  {
    path: "/index",
    name: "index",
    component: () => import('../views/index/index')
  },
  {
    path: "/deposit",
    name: "deposit",
    component: () => import('../views/index/deposit')
  },
  {
    path: "/financing",
    name: "financing",
    component: () => import('../views/financing/index')
  },
  {
    path: "/record",
    name: "record",
    component: () => import('../views/record/index')
  },
  {
    path: "/my",
    name: "my",
    component: () => import('../views/my/index')
  },
  {
    path: "/transactionRecords",
    name: "transactionRecords",
    component: () => import('../views/record/transactionRecords')
  },
  {
    path: "/bettingRecord",
    name: "bettingRecord",
    component: () => import('../views/record/bettingRecord')
  },
  {
    path: "/limitConversion",
    name: "limitConversion",
    component: () => import('../views/my/limitConversion')
  },

  {
    path: "/rewardDetails",
    name: "rewardDetails",
    component: () => import('../views/record/rewardDetails')
  },
  {
    path: "/purchaseRecord",
    name: "purchaseRecord",
    component: () => import('../views/record/purchaseRecord')
  },
  {
    path: "/platformStatement",
    name: "platformStatement",
    component: () => import('../views/my/platformStatement')
  },
  {
    path: "/contactUs",
    name: "contactUs",
    component: () => import('../views/my/contactUs')
  },
  {
    path: "/moreGames",
    name: "moreGames",
    component: () => import('../views/index/moreGames')
  },
  {
    path: "/team",
    name: "team",
    component: () => import('../views/financing/team')
  },
  {
    path: "/recordLog",
    name: "recordLog",
    component: () => import('../views/financing/record')
  },
  {
    path: "/treeTest",
    name: "treeTest",
    component: () => import('../views/financing/treeTest')
  },
  {
    path: "/withdraw",
    name: "withdraw",
    component: () => import('../views/index/withdraw')
  },
  {
    path: "/withdrawalDetails",
    name: "withdrawalDetails",
    component: () => import('../views/my/withdrawalDetails')
  },
  {
    path: "/withdrawalRecords",
    name: "withdrawalRecords",
    component: () => import('../views/my/withdrawalRecords')
  },
  {
    path: "/participate",
    name: "participate",
    component: () => import('../views/financing/participate')
  },
  {
    path: "/bindEmail",
    name: "bindEmail",
    component: () => import('../views/my/bindEmail')
  },
  {
    path: "/inviteFriends",
    name: "inviteFriends",
    component: () => import('../views/my/inviteFriends')
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () => import('../views/financing/agreement')
  },
  {
    path: "/notice",
    name: "notice",
    component: () => import('../views/financing/notice')
  },
]

const router = new Router({
  // 这里配置的是路由和组件的映射关系, 是一个数组.
  routes,
  scrollBehavior() {
    //x代表横坐标，y代表纵坐标
    return {x: 0, y: 0};
  },
  mode: 'history'
})

export default router
