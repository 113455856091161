<template>
  <div>
    <router-view/>
    <Tabbar v-if="status" :value="value" @changeTab="changeTab"/>
  </div>
</template>

<script>
import Tabbar from "@/components/Tabbar/index.vue";
import {createWeb3Modal, defaultWagmiConfig} from "@web3modal/wagmi/vue";
import {bsc} from "viem/chains";
import {disconnect, watchAccount} from "@wagmi/core";

export default {
  components: {
    Tabbar,
  },
  data() {
    return {
      initWalletSuccess: false,
      status: false,
      value: "index",
    };
  },
  watch: {
    $route: async function (e) {
      const whiteList = ["index", "financing", "record", "my"];

      this.value = e.name;

      if (e.name == 'my') {
        document.getElementById('ss-chat-p').style.display = 'block';
      } else {
        document.getElementById('ss-chat-p').style.display = 'none';
      }

      if (whiteList.indexOf(e.name) == -1) {
        this.status = false;
      } else {
        this.status = true;
      }

      let data = await this.$api.getAddress();



      if(e.name != 'loading' && data.code == 500 && e.name != 'notFound'){
        this.status = false;
        this.$router.push({
          name: 'notFound'
        })
      }

    },
  },
  mounted() {

    try {
      const projectId = "c735502cf0d064152e29c666871a9ff5";
      const metadata = {
        name: "Web3Modal",
        description: "Web3Modal Example",
        url: "https://web3modal.com",
        icons: ["https://avatars.githubusercontent.com/u/37784886"],
      };

      const chains = [bsc];
      const wagmiConfig = defaultWagmiConfig({chains, projectId, metadata});

      createWeb3Modal({wagmiConfig, projectId, chains});

      // 监听钱包发生变化
      watchAccount(async res => {
        if (res.address && this.$store.state.address) {
          if (localStorage.getItem('username') !== res.address.toLowerCase()) {
            this.$toast(this.$t('qbdzfsbf'));
            await disconnect();
            this.$store.state.address = '';
            if (this.$route.name != 'index') {
              await this.$router.push({
                name: 'index'
              })
            }
          }
        }
      })
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    changeTab(e) {
      this.value = e;
    },
  },
};
</script>
<style>


body, html {
  background: #111723;
}

.jilutitle {
  color: #FFFFFF;
  height: 70px;
  line-height: 70px;
  text-align: center;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.padding-15-box {
  padding: 15px;
  box-sizing: border-box;
}

.ddd {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.xiaofangk {
  width: 4px;
  height: 15px;
  background: #fff;
  border-radius: 2px;
  margin-right: 5px;
}

.jianjie {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
}
</style>
