import apiUtils from "./index";

export default {
  login(data) {
    return apiUtils.memePost('user/login', data);
  },
  index(data) {
    return apiUtils.memePost('index/index', data);
  },
  commonInit(data) {
    return apiUtils.memePost('common/init', data);
  },
  gameGameMenuList(data){
    return apiUtils.gameGet('game/getGameMenuList', data);
  },
  getGameIndexList(data){
    return apiUtils.gameGet('game/getGameIndexList', data);
  },
  walletLog(data) {
    return apiUtils.gameGet('wallet/log', data);
  },
  gameRecord(data){
    return apiUtils.gameGet("game/gameRecord", data);
  },
  user(data) {
    return apiUtils.memePost('user/index', data);
  },
  assignTree(data) {
    return apiUtils.memePost('team/assignTreeAndConfig', data);
  },
  inviteTree(data) {
    return apiUtils.memePost('team/inviteTreeAndConfig', data);
  },
  balance(data) {
    return apiUtils.memePost('user/balance', data);
  },
  wallet(data) {
    return apiUtils.gameGet('wallet/lists', data);
  },
  miningLog(data) {
    return apiUtils.memePost('mining/log', data);
  },
  rewardlog(data) {
    return apiUtils.memePost('mining/rewardLog', data);
  },
  recharge(data) {
    return apiUtils.gameGet('recharge/config', data);
  },
  rechargeSubmit(data) {
    return apiUtils.gamePost('recharge/submit', data);
  },
  playingNgGame(data){
    return apiUtils.gamePost("game/playingNgGame", data);
  },
  gameNewList(data){
    return apiUtils.gameGet("game/getNewGameList", data);
  },
  change(data) {
    return apiUtils.gamePost('wallet/change', data);
  },
  miningDetails(data) {
    return apiUtils.memePost('team/miningDetails', data);
  },
  oneTree(data) {
    return apiUtils.memePost('team/oneTree', data);
  },
  config(data) {
    return apiUtils.memePost('withdraw/config', data);
  },
  submit(data) {
    return apiUtils.memePost('withdraw/submit', data);
  },
  withdraw(data) {
    return apiUtils.memePost('withdraw/log', data);
  },
  withdrawDetail(data) {
    return apiUtils.memePost('withdraw/detail', data);
  },
  balancePayment(data) {
    return apiUtils.gamePost('wallet/balancePayment', data);
  },
  detail(data) {
    return apiUtils.memePost('mining/detail', data);
  },
  bindEmail(data) {
    return apiUtils.memePost('email/bindEmail', data);
  },
  send(data) {
    return apiUtils.memePost('email/send', data);
  },
  teamInvite(data) {
    return apiUtils.memePost('team/invite', data);
  },
  cancelassign(data) {
    return apiUtils.memePost('team/cancelassign', data);
  },
  reinvest(data) {
    return apiUtils.memePost('mining/reinvest', data);
  },
  run(data) {
    return apiUtils.memePost('mining/run', data);
  },
  buy(data) {
    return apiUtils.memePost('mining/buy', data);
  },
  profile(data) {
    return apiUtils.memePost('user/profile', data);
  },
  sponsorlist(data) {
    return apiUtils.memePost('team/sponsorlist', data);
  },
  teamAssignlist(data) {
    return apiUtils.memePost('team/assignlist', data);
  },
  support(data) {
    return apiUtils.memePost('team/support', data);
  },
  team(data) {
    return apiUtils.memePost('team/lists', data);
  },
  teamConfig(data) {
    return apiUtils.memePost('team/config', data);
  },
  webview(data) {
    return apiUtils.memePost('common/webview', data);
  },
  assign3lists(data) {
    return apiUtils.memePost('team/assign3lists', data);
  },
  codetoassign(data) {
    return apiUtils.memePost('team/codetoassign', data);
  },
  userRate(data) {
    return apiUtils.memePost('user/rate', data);
  },
  verificationCode(data) {
    return apiUtils.memePost('email/verificationCode', data);
  },
  getBroadcastList(){
    return apiUtils.gameGet("game/getBroadcastList");
  },
  reinvestOne(data){
    return apiUtils.memePost("mining/reinvestOne",data);
  },
  getAddress(){
    return apiUtils.memeGet("user/getAddress");
  },
  inviteTree2(data){
    return apiUtils.memePost("team/inviteTree",data);
  },
  assignTree2(data){
    return apiUtils.memePost("team/assignTree",data);
  },
  payRecharge(data){
    return apiUtils.memePost("recharge/payRecharge",data);
  },
  getPopularGames(){
    return apiUtils.gameGet("game/getPopularGames");
  }
}
