import Vue from 'vue'
import App from './App.vue'
import router from './router'
Vue.config.productionTip = false
// import Vconsole from 'vconsole';
// new Vconsole();
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
import api from './request/api.js'
Vue.prototype.$api = api;
import { Toast } from 'vant';
Vue.use(Toast);

Vue.prototype.$toast = function (msg){
  Toast({
    message: msg,
    position: 'bottom',
  });
}

import IconTitle from './components/TitleIcon';
Vue.component('IconTitle',IconTitle);

Vue.prototype.RechargeFees = 2; // 2u充值手续费
Vue.prototype.WithdrawalFees = 2; // 提现1000u内  扣除2u手续费
Vue.prototype.WithdrawalFees1000 = 20; // 提现1000u以上  扣除20u手续费

import VueI18n from 'vue-i18n';
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'ko',
  messages: {
    en: require('../src/lang/en.json'),
    zh: require('../src/lang/zh.json'),
    ko: require('./lang/kr.json'),
    RU: require('./lang/RU.json'),
    DE: require('./lang/DE.json'),
    ES: require('./lang/ES.json'),
    JA: require('./lang/JA.json'),
    TH: require('./lang/TH.json'),
    VI: require('./lang/VI.json'),
  }
});

Vue.prototype.$iconImg = 'https://img.happyplay.game';


import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard)

Vue.prototype.$copy = function (value){

  const aux = document.createElement('input')
  aux.setAttribute('value', value)
  document.body.appendChild(aux)
  aux.select()
  document.execCommand('copy')
  document.body.removeChild(aux)
  Toast({
    message: this.$t('fzgc'),
    position: 'bottom',
  });
}

import store from './store'

Vue.prototype.$store = store

new Vue({
  i18n,
  store,
  router,
  render: h => h(App),
}).$mount('#app')
