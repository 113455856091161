<template>
  <div class="biaotia">
    <img v-if="type == 1" src="../../../src/assets/newBcGame/rm.png" class="rm">
    <img v-if="type == 2" src="../../../src/assets/newBcGame/lc.png" class="qb">
    <img v-if="type == 3" src="../../../src/assets/newBcGame/sp.png" class="sp">
    <img v-if="type == 4" src="../../../src/assets/newBcGame/tq.png" class="tq">
    <img v-if="type == 5" src="../../../src/assets/newBcGame/zhzd.png" class="zhzd">
    <img v-if="type == 6" src="../../../src/assets/newBcGame/fk.png" class="fk">
    <img v-if="type == 7" src="../../../src/assets/newBcGame/lcjl.png" class="lcjl">
    <img v-if="type == 8" src="../../../src/assets/newBcGame/sp.png" class="sp">
    <img v-if="type == 9" src="../../../src/assets/newBcGame/gn.png" class="gn">
    <img v-if="type == 10" src="../../../src/assets/newBcGame/tq.png" class="tq">
    <img v-if="type == 11" src="../../../src/assets/newBcGame/usdticon.png" class="usdticon">
    <img v-if="type == 12" src="../../../src/assets/newBcGame/dizhi.png" class="dizhi">
    <img v-if="type == 13" src="../../../src/assets/newBcGame/zwl.png" class="zwl">
    <img v-if="type == 14" src="../../../src/assets/newBcGame/tdcc.png" class="zwl">
    <div class="rmbt">{{ ['',$t('rmyx'),$t('wdqb2'),$t('yxtj'),$t('ytrje'),$t('zhzdhbed'),'','','','',''][type] }}<slot name="value"></slot></div>
  </div>
</template>

<script>
export default {
  props: ['type'],
  data() {
    return {}
  },
  methods: {},
  mounted() {

  }
}
</script>


<style scoped>

.dizhi{
  width: 17px;
  height: 17px;
  display: flex;
  margin: auto 0;
}

.zwl{
  width: 17px;
  height: 17px;
  display: flex;
  margin: auto 0;
}

.usdticon{
  width: 35px;
  height: 35px;
  display: flex;
  margin: auto 0;
}

.gn{
  width: 14px;
  height: 14px;
  display: flex;
  margin: auto 0;
}

.lcjl{
  width: 15px;
  height: 15px;
  display: flex;
  margin: auto 0;
}

.fk{
  width: 15px;
  height: 16px;
  display: flex;
  margin: auto 0;
}

.zhzd{
  width: 14px;
  height: 15px;
  display: flex;
  margin: auto 0;
}

.tq{
  width: 15px;
  height: 15px;
  display: flex;
  margin: auto 0;
}

.rm {
  width: 10px;
  height: 14px;
  display: flex;
  margin: auto 0;
}

.qb{
  width: 11px;
  height: 13px;
}

.sp{
  width: 15px;
  height: 11px;
}

.rmbt {
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
  margin-left: 5px;
}

.biaotia {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
</style>
